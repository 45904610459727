<template>
    <div class="d-flex flex-column">
        <h5 class="h5-text mb-2">Отчет по компании</h5>
        <div class="page-block flex-column mb-6">
            <v-row class="mt-3">
                <v-col
                    cols="12"
                    sm="2"
                ><v-menu
                    ref="menu"
                    v-model="dateSearchMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="startDate"
                            label="Дата начала отчета"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="startDate"
                        @input="dateSearchMenu = false"
                    >
                    </v-date-picker>
                </v-menu>
                </v-col>
                <v-col
                    cols="12"
                    sm="2"
                >
                    <v-menu
                        ref="menu2"
                        v-model="dateSearchMenu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="endDate"
                                label="Дата конца отчета"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="endDate"
                            @input="dateSearchMenu2 = false"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col
                    cols="12"
                    sm="2"
                >
                    <v-btn
                        depressed
                        color="primary"
                        @click="getReport()"
                        block
                        :loading="loading"
                    >
                        Сформировать отчет
                    </v-btn>
                    <v-btn
                        depressed
                        color="primary"
                        data-click="exportFile()"
                        data-loading="fileLoading"
                        class="mt-2"
                        block
                        @click="exportFile()"
                        :loading="loading"
                    >
                        Скачать отчет
                    </v-btn>
                </v-col>
            </v-row>
            <div class="mt-3">
                <ExtReportTable :headers="headers" :items="items" v-if="items.length"></ExtReportTable>
            </div>
        </div>
    </div>
</template>
<script>
    import { Http } from '@/apis/Http'
    import ExtReportTable from '@/views/Admin/Reports/components/ExtReportTable.vue'

    export default {

        components: {
            ExtReportTable
        },

        data() {
            return {
                companyIds: 0,
                items: [],
                headers: [],
                startDate: null,
                endDate: null,
                dateSearchMenu: false,
                dateSearchMenu2: false,
                loading: false
            }
        },

        methods: {

            downloadReport() {
                this.loading = true
                Http.get('/admin/download-company-report', {
                    params: {
                        filePath: this.filePath
                    },
                    responseType: 'blob'
                })
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        const fileName = this.filePath.split('/').pop()
                        link.setAttribute('download', fileName)
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    })
                    .catch(() => {
                        this.showErrorSystemNotification('Ooops!')
                    })
                    .finally(() => { this.loading = false })
            },

            getReport() {
                this.loading = true
                Http.get('/admin/extended-report', {
                    params: {
                        startDate: this.startDate,
                        endDate: this.endDate,
                        companyIds: []
                    }
                })
                    .then(res => {
                        if (res?.data) {
                            this.headers = res.data.headers
                            this.items = res.data.items
                        }
                    })
                    .catch(() => {
                        this.showErrorSystemNotification('Ooops!')
                    })
                    .finally(() => { this.loading = false })
            },

            exportFile() {
                if (!this.startDate || !this.endDate) {
                    this.showErrorSystemNotification('Не заполнены поля даты')
                    return
                }

                this.loading = true

                Http.get('/admin/extended-report-export', {
                    params: {
                        startDate: this.startDate,
                        endDate: this.endDate,
                        companyIds: []
                    },
                    responseType: 'blob'
                })
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        const fileName = response.headers?.['content-disposition'] ? response.headers['content-disposition'].split('filename=')[1] : 'logs-' + this.startDate + '-' + this.endDate + '.xlsx'
                        link.setAttribute('download', fileName)
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    })
                    .catch((e) => {
                        console.log(e)
                        this.showErrorSystemNotification('Ooops!')
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
</script>
