<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="perPage"
      dense
    ></v-data-table>
  </div>
</template>
<script>
  export default {
    props: ['items', 'headers'],
    data() {
      return {
        perPage: 500
      }
    }
  }
</script>
